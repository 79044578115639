import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SeoMeta"
import HomeContent from "../components/pages/HomeContent"

const IndexPage = props => {
  const { siteMetadata } = props.data.site
  const seoImage = props?.data?.contentfulProjectSection?.featuredImage?.fixed
  return (
    <>
      <SEO {...siteMetadata} image={seoImage} title="Projects" />
      <HomeContent grey={false} time={0} {...props.data} />
    </>
  )
}

export const query = graphql`
  query ProjectPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulProjectSection {
      featuredImage {
        fixed(width: 1200, height: 630) {
          src
          height
          width
        }
      }
      sets {
        title
        contentfulid
        featuredImage {
          id
          title
          description
          fixed(width: 600) {
            height
            src
            width
          }
          fluid(maxWidth: 800, maxHeight: 400) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`

export default IndexPage
